@import url('https://fonts.cdnfonts.com/css/satoshi');

html,
body,
#root,#root>div,.container {
  margin: 0;
  width: 100%;
  height: 100%;
}

h1 {
  color: #fff;
  font-size: 40px;
  font-family: 'Satoshi', sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 15px;
}

h2 {
  color: #FFFFFF;
  font-family: 'Satoshi', sans-serif;
  text-align: center;
}

p {
  width: 35%;
  margin: 0;
  font-weight: 400;
  color: #FFFFFF;
  font-family: 'Satoshi', sans-serif;
  font-size: 21px;
  text-align: center;
}

body {
  background-color: #0D0D16;
}

.container {
  width: 100%;
  align-items: center;
  justify-content: center;
  animation: OnLoad ease-out 0.75s;
}

@keyframes OnLoad {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.not-scrollable {
  height: 100vh;
}


.spacer {
  height: 1rem;
}

.highlight {
  background: linear-gradient(#3081F7 0%, #6aa5ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

button {
  appearance: none;
  border: none;
  font-family: 'Satoshi', sans-serif;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  border-radius: 50px;
  background-color: #2D2C37;
  color: #FFFFFF;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
}

button:hover {
  background-color: #383744;
}

button:active {
  background-color: #454b69;
  scale: 0.95;
}

.hlbutton {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
}

.hlbutton {
  background: linear-gradient(#3081F7 0%, #6aa5ff 100%);
  -webkit-box-shadow:0px 0px 18px 0px rgba(46,130,255,0.83);
-moz-box-shadow: 0px 0px 18px 0px rgba(46,130,255,0.83);
box-shadow: 0px 0px 18px 0px rgba(46,130,255,0.83);
font-family: 'Satoshi', sans-serif;
}

*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
*::-webkit-scrollbar-track {
  background-color: 0 0;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.hero-section.top {
  background: linear-gradient(180deg, rgba(56, 76, 192,.5) 0%, #0D0D16 100%);
}

.fade-in-visible {
  opacity: 1;
}
