
.site-title h1 {
    display: inline;
}

h1, h2 {
    text-align: left;
}

p, ul {
    text-align: center;
}

ul {
    list-style: none;
    padding: 0;
    color: whitesmoke;
}

li {
    margin: 5px 0;
    color: whitesmoke;
}
