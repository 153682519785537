

.product {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.product-image {
    width: 600px;
    height: 350px;
}

.product-desc {
    text-align: left;
    padding-left: 17.5px;
    font-size: 16px;
    width: 100%;
}

.product-button {
    margin-left: 17px;
    margin-top: 17px;
}


.product-title {
    text-align: left;
}

.product-explain {
    width: 25%;
    height: 300px;
    align-items: center;
    justify-content: center;
}


@media (max-width: 500px) {
    .product {
        flex-direction: column;
    }
    
  }