
.top-navigation {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 9999;
  justify-content: center;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.75s;
  margin-top: 7.5px;
}


.top-navigation.scrolled {
  margin-top: 15px;
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70rem;
    height: 5%;
    background-color: transparent;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.75s;
    border-radius: 6974px;
    padding: 15px;
    backdrop-filter: blur(12px);
}
.navbar.scrolled {
  background: #1111118e;
}
a {
    text-decoration: none;
    transition-duration: .25s;
    border-radius: 10px;
    padding: 10px 15px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    margin: 0 10px;
    background: transparent;
    font-weight: 400;
    font-size: 18px;
    color: rgb(255, 255, 255);
}
.navbar.scrolled a {
  background: transparent;
}
.navbar a:hover {
  background: #2b2b2b;
}
.navbar a.active {
    color: #3081F7;
}