.title {
  width: 45%;
  height: 300px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
}
.titledesc {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.control {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
}

.titleimg {
  height: 80%;
  width: 1;
}
h1 {
  margin-top: 0;
  margin-bottom: 0;
  height: 45px;
  white-space: nowrap;
}
h2 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 32.5px;
  width: 55.5%;
  padding-bottom: 15px;
}

@media (max-width: 500px) {
  .title {
    height: 100px;
    width: 75%;

  }
  .titleimg {
      height: 90%;
      width: 1;
  }
  h1 {
      margin-top: 0;
      margin-bottom: 0;
      height: 45px;
      font-size: 30px;
      white-space: nowrap;
  }
  h2 {
      font-size: 16px;
      line-height: 22.5px;
      width: 90%;
  }
  
}